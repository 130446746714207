import axiosInstance from "../../auth/axiosInstance";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { TypeAnimation } from "react-type-animation";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Pagination,
} from "@mui/material";
import styled from "styled-components";
import Card from "../structure/card";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import { CountryContext } from "../structure/CountryContext";
import Flag from "react-world-flags";
import PublicIcon from "@mui/icons-material/Public";

const StyledFormControl = styled(FormControl)`
  min-width: 230px;
  margin-left: 8px;

  @media (max-width: 767px) {
    margin-top: 12px;
    margin-left: -30px;
  }
`;

const Biblio = () => {
  const [cours, setCours] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedMatiere, setSelectedMatiere] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("Tout");
  const { country, setCountry } = useContext(CountryContext);

  const matiereOptions = [
    { label: "  Tous les matières ", value: "All" },
    { label: "  **  Sciences  ** ", value: null },
    { label: "Mathématiques", value: "maths" },
    { label: "Physique-Chimie", value: "pc" },
    { label: "Sciences de la Vie et de la Terre", value: "svt" },
    { label: "Sciences de l'Ingénieur", value: "science_ingenieur" },
    { label: "  **  Langues  ** ", value: null },
    { label: "Arabe", value: "arabe" },
    { label: "Anglais", value: "anglais" },
    { label: "Français", value: "francais" },
    { label: "Espagnol", value: "espagnol" },
    { label: "  **  Matières littéraires  ** ", value: null },
    { label: "Éducation Islamique", value: "education_islamique" },
    { label: "Philosophie", value: "philosophie" },
    { label: "Histoire", value: "histoire" },
    { label: "  **  Technologie  ** ", value: null },
    { label: "Informatique", value: "info" },
    { label: "Électronique", value: "electronique" },
    { label: "  **  Autre  ** ", value: null },
    { label: "Économie", value: "economie" },
    { label: "Autre", value: "autre" },
  ];

  const levelOptions = [
    { label: "** Université **", value: null },
    { label: "Universitaire", value: "Universitaire" },

    { label: "** Classes Préparatoires **", value: null },
    { label: "Mathématiques et Physique", value: "MP" },
    { label: "Physique et Sciences de l'Ingénieur", value: "PSI" },
    { label: "Technologie et Sciences Industrielles", value: "TSI" },
    { label: "Économique et Commerciale (ECS)", value: "ECS" },
    { label: "Économique et Commercialeb(ECT)", value: "ECT" },

    { label: "** Lycée **", value: null },
    { label: "Terminale (BAC 2)", value: "BAC_2" },
    { label: "Première (BAC 1)", value: "BAC_1" },
    { label: "Tronc Commun (T.C)", value: "T_C" },

    { label: "** Collège **", value: null },
    { label: "3ème Collège", value: "College_3" },
    { label: "2ème Collège", value: "College_2" },
    { label: "1ère Collège", value: "College_1" },

    { label: "** Autres **", value: null },
    { label: "Tous Niveaux", value: "Tout" },
    { label: "Autre", value: "Autre" },
  ];

  const countryData = [
    { code: "MA", name: "Maroc" },
    { code: "TN", name: "Tunisie" },
    { code: "EG", name: "Égypte" },
    { code: "AE", name: "UAE" },
    { code: "SA", name: "Arabie Saoudite" },
    { code: "QA", name: "Qatar" },
    { code: "FR", name: "France" },
    { code: "ES", name: "Espagne" },
    { code: "GB", name: "Royaume-Uni" },
    { code: "US", name: "États-Unis" },
    { code: "XX", name: "Autre" },
    { code: "UN", name: "Monde entier" },
  ];

  const [prixMin, setPrixMin] = useState("");
  const [prixMax, setPrixMax] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(18);
  const [totalCours, setTotalCours] = useState(0);
  const [searchOld, setSearchOld] = useState("");

  const fetchData = useCallback(async () => {
    setLoading(true);
    const sanitizeSearch = DOMPurify.sanitize(searchValue);
    const params = {
      page: currentPage - 1,
      pageSize: itemsPerPage,
    };

    try {
      let response;
      if (searchType === "") {
        response = await axiosInstance.get("/cours/v", {
          params: {
            country: country,
            ...params,
          },
        });
      } else if (searchType === "matiere") {
        if (selectedMatiere === "All") {
          response = await axiosInstance.get("/cours/v", {
            params: {
              country: country,
              ...params,
            },
          });
        } else {
          response = await axiosInstance.get(`/cours/sub`, {
            params: {
              subject: selectedMatiere,
              level: selectedLevel,
              country: country,
              ...params,
            },
          });
        }
      } else if (searchType === "prof") {
        response = await axiosInstance.get(`/cours/pro`, {
          params: {
            nom: sanitizeSearch,
            ...params,
          },
        });
      } else if (searchType === "ville") {
        response = await axiosInstance.get(`/cours/vil`, {
          params: {
            ville: sanitizeSearch,
            ...params,
          },
        });
      } else if (searchType === "prix") {
        response = await axiosInstance.get(`/cours/pri`, {
          params: {
            min: prixMin,
            max: prixMax,
            country: country,
            ...params,
          },
        });
      }

      if (response && response.data) {
        setCours(response.data.content);
        setTotalCours(response.data.totalElements);
      } else {
        setCours([]);
      }
    } catch (error) {
      console.error("Error ");
      setCours([]);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    searchValue,
    searchType,
    selectedMatiere,
    selectedLevel,
    prixMin,
    prixMax,
    country,
    itemsPerPage,
  ]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleChange = (event) => {
    const newCountry = event.target.value;
    setCountry(newCountry);
  };

  useEffect(() => {
    console.log("Updated country:", country);
  }, [country]);

  const handleSearch = () => {
    const sanitizeSearch = DOMPurify.sanitize(searchValue);
    if (searchOld !== sanitizeSearch || currentPage !== 1) {
      setSearchOld(sanitizeSearch);
      setCurrentPage(1);
    } else {
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [currentPage, country]);

  useEffect(() => {
    if (searchType === "prix" || searchType === "matiere") {
      setCurrentPage(1);
    }
  }, [searchType, prixMin, prixMax, selectedMatiere]);

  const choosePicture = (coursSubject, coursLevel) => {
    const specialLevels = [
      "BAC_1",
      "BAC_2",
      "MP",
      "PSI",
      "TSI",
      "ECS",
      "ECT",
      "Universitaire",
    ];

    switch (coursSubject) {
      case "maths":
        return specialLevels.includes(coursLevel)
          ? "/img/maths_ad.jpg"
          : "/img/maths.jpg";
      case "pc":
        return specialLevels.includes(coursLevel)
          ? "/img/pc_ad.jpg"
          : "/img/pc.jpg";
      case "science_ingenieur":
        return specialLevels.includes(coursLevel)
          ? "/img/si_ad.jpg"
          : "/img/si.jpg";
      case "svt":
        return specialLevels.includes(coursLevel)
          ? "/img/svt_ad.jpg"
          : "/img/svt.jpg";
      case "arabe":
        return "/img/arabe.jpg";
      case "anglais":
        return specialLevels.includes(coursLevel)
          ? "/img/eng_ad.jpg"
          : "/img/english.jpg";
      case "francais":
        return specialLevels.includes(coursLevel)
          ? "/img/fr_ad.jpg"
          : "/img/fr.jpg";
      case "espagnol":
        return "/img/espagnol.jpg";
      case "education_islamique":
        return "/img/E_I.jpg";
      case "philosophie":
        return "/img/philosophie.jpg";
      case "histoire":
        return specialLevels.includes(coursLevel)
          ? "/img/histoire_ad.jpg"
          : "/img/histoire.jpg";
      case "info":
        return "/img/info.jpg";
      case "electronique":
        return "/img/electronique.jpg";
      case "economie":
        return specialLevels.includes(coursLevel)
          ? "/img/eco_ad.jpg"
          : "/img/economie.jpg";
      case "autre":
        return "/img/autre.jpg";
      default:
        return "/img/autre.jpg";
    }
  };

  return (
    <div>
      <Helmet>
        <title>Bibliothèque des cours</title>
        <meta
          name="description"
          content="Découvrez notre bibliothèque en ligne offrant une variété de cours de soutien pour étudiants au Maroc. Accédez à des ressources éducatives de qualité pour améliorer vos compétences. Cours de soutien en ligne"
        />
        <link rel="canonical" href="https://www.eswaye.com/biblio" />
      </Helmet>
      <br />

      <Box
        sx={{
          position: "relative",
          top: { xs: 10, sm: 10 },
          left: { xs: 20, sm: 20 },
          width: { xs: 150, sm: 180 },
        }}
      >
        <FormControl fullWidth size="small">
          {" "}
          <Select
            value={country}
            onChange={handleChange}
            displayEmpty
            style={{ minWidth: 200 }}
          >
            {countryData.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                {country.code === "UN" || country.code === "XX" ? (
                  <PublicIcon
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 10,
                      verticalAlign: "middle",
                    }}
                  />
                ) : (
                  <Flag
                    code={country.code}
                    style={{
                      width: 20,
                      height: 15,
                      marginRight: 10,
                      verticalAlign: "middle",
                    }}
                  />
                )}
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <br />
      <TypeAnimation
        sequence={[
          " Bibliothèque des Cours ",
          1500,
          " Cours Mathématiques ",
          1500,
          " Cours Physique-Chimie ",
          1500,
          " Cours de la langue Arabe ",
          1500,
          " Cours des langues étrangères ",
          1500,
          " Anglais - Français - Espagnol ",
          1500,
          " Cours d'Éducation Islamique ",
          1500,
          " Cours Philosophie et Histoire ",
          1500,
          " Informatique - Électronique ",
          1500,
          " Économie - Comptabilité ",
        ]}
        speed={50}
        className="type-animation"
        repeat={Infinity}
      />
      <div
        style={{
          display: "flex",
          gap: "8px",
          marginBottom: "20px",
          marginTop: "50px",
          marginLeft: "5%",
          marginRight: "2px",
        }}
      >
        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel id="search-type-label">Filtre</InputLabel>
          <Select
            labelId="search-type-label"
            id="search-type"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            label="Search By"
          >
            <MenuItem value="matiere">Matière</MenuItem>
            <MenuItem value="prof">Professeur</MenuItem>
            <MenuItem value="ville">Ville</MenuItem>
            <MenuItem value="prix">Prix</MenuItem>
          </Select>
        </FormControl>

        {searchType === "prix" ? (
          <>
            <TextField
              label="Min"
              variant="outlined"
              value={prixMin}
              onChange={(e) => setPrixMin(e.target.value)}
              placeholder="Enter Min PRIX"
            />
            <TextField
              label="Max"
              variant="outlined"
              value={prixMax}
              onChange={(e) => setPrixMax(e.target.value)}
              placeholder="Enter Max PRIX"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      onClick={handleSearch}
                      startIcon={<SearchIcon />}
                      disabled={!prixMax || !prixMin}
                    >
                      Go
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </>
        ) : searchType === "matiere" ? (
          <div>
            <FormControl variant="outlined" style={{ minWidth: 230 }}>
              <InputLabel id="matiere-label">Matière</InputLabel>
              <Select
                labelId="matiere-label"
                id="matiere"
                value={selectedMatiere}
                onChange={(e) => setSelectedMatiere(e.target.value)}
                label="Matiere"
              >
                {matiereOptions.map((option) => (
                  <MenuItem
                    key={option.label || option}
                    value={option.value || option}
                    disabled={option.value === null}
                  >
                    {option.label || option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedMatiere && selectedMatiere !== "All" && (
              <StyledFormControl variant="outlined">
                <InputLabel id="level-label">Niveau</InputLabel>
                <Select
                  labelId="level-label"
                  id="level"
                  value={selectedLevel}
                  onChange={(e) => setSelectedLevel(e.target.value)}
                  label="Niveau"
                >
                  {levelOptions.map((option) => (
                    <MenuItem
                      key={option.label || option}
                      value={option.value || option}
                      disabled={option.value === null}
                    >
                      {option.label || option}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            )}
            <Button
              variant="contained"
              onClick={handleSearch}
              startIcon={<SearchIcon />}
              style={{ margin: "8px" }}
            >
              Chercher
            </Button>
          </div>
        ) : searchType === "prof" ? (
          <TextField
            label="Nom"
            variant="outlined"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    onClick={handleSearch}
                    startIcon={<SearchIcon />}
                  >
                    Chercher
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <TextField
            variant="outlined"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    onClick={handleSearch}
                    startIcon={<SearchIcon />}
                  >
                    Chercher
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        )}
      </div>
      {loading ? (
        <div
          className="spinner-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">Chargement...</span>
          </div>
        </div>
      ) : (
        <div>
          {cours.length === 0 ? (
            <div className="not-found-message">
              <p>Aucun cours correspondant n'a été trouvé.</p>
            </div>
          ) : (
            <div className="container" style={{ paddingTop: "20px" }}>
              <div className="row">
                {cours.map((cours) => (
                  <div className="col-md-4 col-sm-6 mb-3" key={cours.idCours}>
                    <Card
                      id={cours.idCours}
                      titre={cours.title}
                      description={cours.description}
                      prix={cours.price}
                      coursSubject={cours.coursSubject}
                      coursLevel={cours.coursLevel}
                      imageSource={choosePicture(
                        cours.coursSubject,
                        cours.coursLevel
                      )}
                      profName={`${cours.profNom} ${cours.profPrenom}`}
                      ecole={cours.ecole}
                      ville={cours.ville}
                      url="coursdetails"
                      isVerified={cours.profVerified}
                      rating={cours.rating}
                      count={cours.count}
                    />
                  </div>
                ))}
              </div>
              <Pagination
                count={Math.ceil(totalCours / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                  marginBottom: "30px",
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Biblio;
