import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from "@mui/material";
import axiosInstance from "../../auth/axiosInstance";
import React, { useEffect, useState } from "react";
import { AuthData } from "../../auth/AuthWrapper";
import { fetchXsrfToken } from "../../auth/fetchXsrfToken";

const Operations = () => {
  const { user } = AuthData();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const csrfToken = await fetchXsrfToken();
        const response = await axiosInstance.get(`/history`, {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        });
        setRows(response.data);
      } catch (error) {
        console.error("Error fetching rows");
      }
    };

    fetchData();
  }, [user.name]);
  const totalMontant = rows.reduce((acc, row) => {
    if (row.operation === "retrait") {
      return acc + row.montant; // use minus when start using solde
    } else {
      return acc + row.montant;
    }
  }, 0);

  return (
    <div>
      {rows.length === 0 ? (
        <div className="not-found-message">
          <p style={{ marginBottom: "15%" }}>
            Aucune Opération n'a été trouvée.
          </p>
        </div>
      ) : (
        <TableContainer
          component={Paper}
          sx={{ width: "90%", margin: "5%", marginTop: "6rem", boxShadow: 3 }}
        >
          <Typography
            variant="h5"
            component="div"
            align="center"
            sx={{ padding: "1rem 0", color: "IndianRed" }}
          >
            Opération Détails
          </Typography>
          <Table sx={{ minWidth: 650 }} aria-label="spanning table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell
                  align="center"
                  colSpan={5}
                  sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                >
                  Détails
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
                >
                  Montant Total: {totalMontant}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" sx={{ fontWeight: "bold", width: 90 }}>
                  Date Opération
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold", width: 90 }}>
                  ID Opération
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold", width: 90 }}>
                  Motif
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold", width: 90 }}>
                  Opération
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold", width: 90 }}>
                  Agent
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold", width: 90 }}>
                  Montant
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.idOp}
                  sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}
                >
                  <TableCell align="right" sx={{ width: 90 }}>
                    {new Date(row.dateOp).toLocaleString()}
                  </TableCell>
                  <TableCell align="right" sx={{ width: 90 }}>
                    {row.idOp}
                  </TableCell>
                  <TableCell align="right" sx={{ width: 90 }}>
                    {row.motif}
                  </TableCell>
                  <TableCell align="right" sx={{ width: 90 }}>
                    {row.operation}
                  </TableCell>
                  <TableCell align="right" sx={{ width: 90 }}>
                    {row.admin}
                  </TableCell>
                  <TableCell align="right" sx={{ width: 90 }}>
                    {row.montant}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Operations;
