import FlagIcon from "@mui/icons-material/Flag";
import CautionIcon from "@mui/icons-material/Warning";
import {
  Button,
  Container,
  IconButton,
  Paper,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../auth/axiosInstance";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper";
import Loading from "../structure/Loading";
import { fetchXsrfToken } from "../../auth/fetchXsrfToken";
import DOMPurify from "dompurify";
import "../customCss/Cours.css";

const CoursDetailsProf = () => {
  const { idCours } = useParams();
  const [selectedCours, setSelectedCours] = useState(null);
  const [eleves, setEleves] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = AuthData();
  const [linkInput, setLinkInput] = useState("");
  const daysOfWeek = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  const coursLevelMapping = {
    College_1: "1ère Collège",
    College_2: "2ème Collège",
    College_3: "3ème Collège",
    T_C: "Tronc Commun",
    BAC_1: "1ère Année Bac",
    BAC_2: "2ème Année Bac",
    Universitaire: "Universitaire",
    MP: "Prépa MP",
    PSI: "Prépa PSI",
    TSI: "Prépa TSI",
    ECS: "Prépa ECS",
    ECT: "Prépa ECT",
    Tout: "Tous Niveaux",
    Autre: "Autre",
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [listVisible, setListVisible] = useState(false);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverHover = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverLeave = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const fetchCoursDetails = async () => {
      try {
        const response = await axiosInstance.get(`/cours/i?${idCours}`);
        setSelectedCours(response.data);
      } catch (error) {
        console.error("Error ");
      } finally {
        setLoading(false);
      }
    };

    fetchCoursDetails();
  }, [idCours, user.id]);

  const handleFlagButtonClick = async () => {
    try {
      const csrfToken = await fetchXsrfToken();
      await axiosInstance.post(
        `/cours/reqDel/${idCours}`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      setSelectedCours((prevState) => ({
        ...prevState,
        deleteRequest: true,
      }));
    } catch (error) {
      console.log("Error ");
    }
  };

  useEffect(() => {
    const fetchEleve = async () => {
      try {
        const response = await axiosInstance.get(`/mescours/count?${idCours}`);
        setEleves(response.data);
      } catch (error) {
        console.error("Error ");
      }
    };

    fetchEleve();
  }, [idCours, eleves]);

  const handleLinkInputChange = (e) => {
    setLinkInput(e.target.value);
  };

  const handleSaveLink = async (e) => {
    e.preventDefault();
    try {
      const csrfToken = await fetchXsrfToken();
      const sanitizeLink = DOMPurify.sanitize(linkInput);
      await axiosInstance.put(`/cours/${idCours}`, sanitizeLink, {
        withCredentials: true,
        headers: {
          "X-XSRF-TOKEN": csrfToken,
        },
      });
      setMessage("Le lien est enregistré avec succès!");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error ");
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!selectedCours) {
    return <Typography variant="h4">Cours Indisponible </Typography>;
  }

  const {
    title,
    coursSubject,
    coursLevel,
    description,
    price,
    date_d,
    date_s,
    date_t,
    deleteRequest,
  } = selectedCours;

  const coursLevelFull = coursLevelMapping[coursLevel] || coursLevel;
  const choosePicture = (coursSubject, coursLevel) => {
    const specialLevels = [
      "BAC_1",
      "BAC_2",
      "MP",
      "PSI",
      "TSI",
      "ECS",
      "ECT",
      "Universitaire",
    ];

    switch (coursSubject) {
      case "maths":
        return specialLevels.includes(coursLevel)
          ? "/img/maths_ad.jpg"
          : "/img/maths.jpg";
      case "pc":
        return specialLevels.includes(coursLevel)
          ? "/img/pc_ad.jpg"
          : "/img/pc.jpg";
      case "science_ingenieur":
        return specialLevels.includes(coursLevel)
          ? "/img/si_ad.jpg"
          : "/img/si.jpg";
      case "svt":
        return specialLevels.includes(coursLevel)
          ? "/img/svt_ad.jpg"
          : "/img/svt.jpg";
      case "arabe":
        return "/img/arabe.jpg";
      case "anglais":
        return specialLevels.includes(coursLevel)
          ? "/img/eng_ad.jpg"
          : "/img/english.jpg";
      case "francais":
        return specialLevels.includes(coursLevel)
          ? "/img/fr_ad.jpg"
          : "/img/fr.jpg";
      case "espagnol":
        return "/img/espagnol.jpg";
      case "education_islamique":
        return "/img/E_I.jpg";
      case "philosophie":
        return "/img/philosophie.jpg";
      case "histoire":
        return specialLevels.includes(coursLevel)
          ? "/img/histoire_ad.jpg"
          : "/img/histoire.jpg";
      case "info":
        return "/img/info.jpg";
      case "electronique":
        return "/img/electronique.jpg";
      case "economie":
        return specialLevels.includes(coursLevel)
          ? "/img/eco_ad.jpg"
          : "/img/economie.jpg";
      case "autre":
        return "/img/autre.jpg";
      default:
        return "/img/autre.jpg";
    }
  };

  const formatCourseStartTime = (dateString) => {
    if (!dateString) return "";

    try {
      const date = Array.isArray(dateString)
        ? new Date(...dateString)
        : new Date(dateString);
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", dateString);
        return "";
      }
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return `${hours < 10 ? "0" : ""}${hours}:${
        minutes < 10 ? "0" : ""
      }${minutes}`;
    } catch (error) {
      console.error("Error parsing date");
      return "";
    }
  };

  const getDayOfWeek = (dateString) => {
    if (!dateString) return "";

    try {
      const [year, month, day] = Array.isArray(dateString)
        ? dateString
        : dateString.split("-");
      const date = new Date(year, month - 1, day);
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", dateString);
        return "";
      }
      const daysOfWeek = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];
      const dayIndex = date.getDay();
      return daysOfWeek[dayIndex];
    } catch (error) {
      console.error("Error parsing date");
      return "";
    }
  };

  const renderDateCell = (date) => {
    if (!date) {
      return "Non défini";
    }

    const timeZone = "Europe/Paris";
    const options = {
      weekday: "long",
      timeZone: timeZone,
    };

    const formattedDate = Array.isArray(date)
      ? new Date(
          date[0],
          date[1] - 1,
          date[2],
          date[3] || 0,
          date[4] || 0
        ).toLocaleDateString("fr-FR", options)
      : new Date(date).toLocaleDateString("fr-FR", options);

    const formattedTime = formatCourseStartTime(date);
    return `${formattedDate} : ${formattedTime}`;
  };

  const renderTimeCell = (date) => {
    if (!date) {
      return "Non défini";
    }

    const formattedTime = formatCourseStartTime(date);
    return formattedTime;
  };

  const countEleve = () => {
    return eleves.length;
  };

  const toggleListVisibility = () => {
    setListVisible(!listVisible);
  };

  const handleValideClick = async (idEleve) => {
    try {
      const csrfToken = await fetchXsrfToken();
      await axiosInstance.post(
        `mescours/val?${idCours}&idEleve=${idEleve}`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      setMessage("L'élève a été ajouté au cours avec succès!");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Operation echouee");
    }
  };

  const handleRemoveClick = async (idEleve) => {
    try {
      const csrfToken = await fetchXsrfToken();
      await axiosInstance.post(
        `mescours/rem?${idCours}&idEleve=${idEleve}`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      );
      setMessage("L'élève a été retiré du cours avec succès!");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Operation echouee");
    }
  };

  return (
    <Container>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          marginTop: "20px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s ease-in-out",
          ":hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <Typography variant="h3" className="title" gutterBottom>
          {title}
        </Typography>
        <img
          src={choosePicture(
            selectedCours.coursSubject,
            selectedCours.coursLevel
          )}
          alt={title}
          className="image-style"
        />
        <div className="grid-details">
          <Typography variant="h6">
            <strong>
              <u>Détails</u> :
            </strong>
          </Typography>
          <Typography className="typography-body1">{description}</Typography>
          <Typography variant="h6">
            <strong>
              <u>Matière</u> :
            </strong>
          </Typography>
          <Typography className="typography-body1">
            {coursSubject} / {coursLevelFull}
          </Typography>
          <Typography variant="h6">
            <strong>
              <u>Prix</u> :
            </strong>
          </Typography>
          <Typography className="typography-body1">
            {price != null ? `${price} MAD` : "N/A"}
          </Typography>
          <Typography variant="h6">
            <strong>
              <u>Première Séance</u> :
            </strong>
          </Typography>
          <Typography className="typography-body1">
            {renderDateCell(date_d, true)}
          </Typography>
          {date_s && (
            <Typography variant="h6">
              <strong>
                <u>Deuxième Séance</u> :
              </strong>
            </Typography>
          )}
          {date_s && (
            <Typography className="typography-body1">
              {renderDateCell(date_s)}
            </Typography>
          )}
          {date_t && (
            <Typography variant="h6">
              <strong>
                <u>Troisième Séance</u> :
              </strong>
            </Typography>
          )}
          {date_t && (
            <Typography className="typography-body1">
              {renderDateCell(date_t)}
            </Typography>
          )}
        </div>

        <Typography
          variant="h6"
          className="professorText"
          style={{ marginLeft: "auto", marginTop: "20px" }}
        >
          Professeur: {`${selectedCours?.profNom} ${selectedCours?.profPrenom}`}
        </Typography>
        <br />

        <Typography
          variant="h6"
          className="eleveText"
          style={{ marginLeft: "auto", marginTop: "20px", color: "Navy" }}
        >
          <div>
            <div
              onClick={toggleListVisibility}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                color: "green",
                fontFamily: "monospace",
              }}
            >
              Eleve inscrits:&nbsp;
              <span style={{ textDecoration: "none" }}>{countEleve()}</span>
              <span
                style={{
                  transition: "transform 0.3s ease-in-out",
                  display: "inline-block",
                  verticalAlign: "middle",
                  transform: listVisible ? "rotate(90deg)" : "rotate(0deg)",
                }}
              >
                ▶
              </span>
            </div>
            {listVisible && (
              <div>
                <ul>
                  {eleves.map((eleve, index) => (
                    <li key={index}>
                      {eleve.nom}
                      {eleve.status ? (
                        <DoneOutlineIcon
                          style={{ marginLeft: "8px", color: "green" }}
                        />
                      ) : eleve.test ? (
                        <span
                          style={{
                            marginLeft: "8px",
                            fontSize: "small",
                            color: "blue",
                          }}
                        >
                          Test
                        </span>
                      ) : (
                        <ClearIcon
                          style={{ marginLeft: "8px", color: "red" }}
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Typography>

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          <strong>
            <u>Emploi du Temps:</u>
          </strong>
        </Typography>

        <TableContainer component={Paper} style={{ marginTop: "10px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lundi</TableCell>
                <TableCell>Mardi</TableCell>
                <TableCell>Mercredi</TableCell>
                <TableCell>Jeudi</TableCell>
                <TableCell>Vendredi</TableCell>
                <TableCell>Samedi</TableCell>
                <TableCell>Dimanche</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {daysOfWeek.map((day, index) => {
                  let matchedDate = null;
                  if (day === getDayOfWeek(selectedCours.date_d)) {
                    matchedDate = selectedCours.date_d;
                  } else if (day === getDayOfWeek(selectedCours.date_s)) {
                    matchedDate = selectedCours.date_s;
                  } else if (day === getDayOfWeek(selectedCours.date_t)) {
                    matchedDate = selectedCours.date_t;
                  }

                  return (
                    <TableCell
                      key={index}
                      style={{
                        background: matchedDate ? "lightgreen" : "inherit",
                      }}
                    >
                      {matchedDate ? renderTimeCell(matchedDate) : ""}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Typography variant="h6">
          <strong>
            <u>Liste des élèves:</u>
          </strong>
        </Typography>
        <br />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Nom</TableCell>
                <TableCell align="center">Séance gratuite</TableCell>
                <TableCell align="center">Statut</TableCell>
                <TableCell align="center">Valider</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eleves.map((eleve) => (
                <TableRow key={eleve.idEleve}>
                  <TableCell align="center">{eleve.nom}</TableCell>
                  <TableCell align="center">
                    {eleve.test ? "Valide" : "Expire"}
                  </TableCell>
                  <TableCell align="center">
                    {eleve.status ? "Payé" : "En attente paiement"}
                  </TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={eleve.status}
                      onChange={(event) => {
                        if (event.target.checked) {
                          handleValideClick(eleve.idEleve); // Switch to green
                        } else {
                          handleRemoveClick(eleve.idEleve); // Switch to grey
                        }
                      }}
                      color="success" // Green when true
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Typography variant="h6">
          <strong>
            <u>Veuillez entrer le lien de la salle de Cours:</u>
          </strong>
        </Typography>
        <TextField
          label="Lien"
          variant="outlined"
          fullWidth
          value={linkInput}
          onChange={handleLinkInputChange}
          style={{ marginBottom: "10px" }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleSaveLink}
            style={{ marginRight: "10px" }}
          >
            Valider
          </Button>
          <IconButton
            onClick={handleFlagButtonClick}
            style={{ color: deleteRequest ? "red" : "inherit" }}
          >
            <FlagIcon />
          </IconButton>
          <Typography
            variant="body1"
            style={{
              marginLeft: "5px",
              color: deleteRequest ? "red" : "inherit",
            }}
          >
            {deleteRequest
              ? "Demande de suppression du cours déjà effectuée"
              : "Demander suppression du cours"}
          </Typography>
          <IconButton
            onMouseEnter={handlePopoverHover}
            style={{ verticalAlign: "middle" }}
          >
            <CautionIcon />
          </IconButton>
        </div>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message={message}
          style={{
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            position: "fixed",
          }}
        />
        <Popover
          open={open}
          onClose={handlePopoverLeave}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper style={{ padding: "10px" }}>
            <Typography variant="body1">
              <span style={{ color: "red" }}>
                <strong>ATTENTION :</strong>
              </span>{" "}
              La suppression demandée est irréversible !
            </Typography>
          </Paper>
        </Popover>
      </Paper>
      <br />
      <br />
    </Container>
  );
};
export default CoursDetailsProf;
