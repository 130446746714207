import React from "react";
import Slideshow from "./Slider";
import { Zoom } from "react-awesome-reveal";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
} from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import "../customCss/Advantages.css";

export const Home = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/biblio");
  };

  const image = {
    url: "/img/biblio.jpg",
    title: "Biblio",
    width: "80%",
  };

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    height: 200,
    borderRadius: "8px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 100,
    },
    "&:hover, &.Mui-focusVisible": {
      zIndex: 1,
      "& .MuiImageBackdrop-root": {
        opacity: 0.15,
      },
      "& .MuiImageMarked-root": {
        opacity: 0,
      },
      "& .MuiTypography-root": {
        border: "4px solid currentColor",
      },
    },
  }));

  const ImageSrc = styled("span")({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  });

  const Image = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  }));

  const ImageBackdrop = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  }));

  const ImageMarked = styled("span")(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  }));

  const reviews = [
    {
      id: 1,
      name: "YASSINE  OM***",
      city: "CASABLANCA",
      review:
        "Inscription rapide , un tres bon prof en maths et horaire respecte",
    },
    {
      id: 2,
      name: "HOUDA AY***",
      city: "RABAT",
      review: "Excellent soutien en anglais .",
    },
    {
      id: 3,
      name: "Hamza RI***",
      city: "MOHEMMADIA",
      review: "Cours mzyan , prof dial maths f un bon niveau, ti chre7 mzyan.",
    },
    {
      id: 4,
      name: "Sami HA***",
      city: "Paris",
      review:
        "Excellent cours de physique pour se préparer aux concours des écoles d'ingénieurs.",
    },
  ];

  const courses = [
    {
      title: "MATHS PREPA",
      description:
        "Cours dedie aux eleves qui veulent acceder au prepa MPSI/PCSI ",
      image: "/img/maths_ad.jpg",
    },
    {
      title: "Physique BAC",
      description: "Cours physique chimie pour preparer a l'examen national.",
      image: "/img/pc_ad.jpg",
    },
    {
      title: "English for Beginners",
      description: "Ce cours pour les debutants en anglais.",
      image: "/img/english.jpg",
    },
    {
      title: "Info",
      description: "Ce cours pour maitriser le langage de programmation Python",
      image: "/img/info.jpg",
    },
  ];

  return (
    <>
      <div className="page">
        <Slideshow />
        <h6
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Pourquoi choisir E-SWAYE?
        </h6>
      </div>
      <div className="home-section-title">
        <h1 className="home-main-title">Pour un Professeur</h1>
        <div className="home-underline"></div>
      </div>
      <Zoom>
        <section className="home-b-advantages b-advantages-3 b-advantages-3_mod-b b-advantages_3-col">
          <i className="home-b-advantages__icon stroke pe-7s-global"></i>
          <div className="home-b-advantages__inner">
            <h3 className="home-b-advantages__title">
              <a href="biblio">L'Endroit Idéal</a>
            </h3>
            <div className="home-b-advantages__info">
              Notre site vous permet de créer et diffuser vos cours de soutien,
              qu'ils soient en présentiel ou à distance, sans avoir à vous
              soucier de trouver un centre dédié.
            </div>
          </div>
        </section>
      </Zoom>
      <br />
      <Zoom>
        <section className="home-b-advantages b-advantages-3 b-advantages-3_mod-b b-advantages_3-col">
          <i className="home-b-advantages__icon stroke pe-7s-global"></i>
          <div className="home-b-advantages__inner">
            <h3 className="home-b-advantages__title">
              <a href="biblio">Service Gratuit</a>
            </h3>
            <div className="home-b-advantages__info">
              Que vos cours soient en ligne ou en présentiel, accueillez un
              nombre d'étudiants adapté à vos besoins et profitez de tous les
              avantages, sans frais.
            </div>
          </div>
        </section>
      </Zoom>
      <br />
      <Zoom>
        <section className="home-b-advantages b-advantages-3 b-advantages-3_mod-b b-advantages_3-col">
          <i className="home-b-advantages__icon stroke pe-7s-global"></i>
          <div className="home-b-advantages__inner">
            <h3 className="home-b-advantages__title">
              <a href="biblio">Etant étudiant</a>
            </h3>
            <div className="home-b-advantages__info">
              Vous aurez accès à un large choix de professeurs, des horaires
              flexibles, et des cours de soutien accessibles partout.
              Sélectionnez la meilleure offre et progressez avec succès dans
              votre parcours scolaire.
            </div>
          </div>
        </section>
      </Zoom>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 300,
          width: "95%",
          marginLeft: "10px",
          marginBottom: "-30px",
        }}
      >
        <ImageButton
          focusRipple
          style={{
            width: image.width,
          }}
          onClick={handleClick}
        >
          <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: "relative",
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              {image.title}
              <ImageMarked className="MuiImageMarked-root" />
            </Typography>
          </Image>
        </ImageButton>
      </Box>

      <Box className="cards-container">
        {courses.map((course, index) => (
          <Card key={index} className="card">
            <CardActionArea onClick={handleClick}>
              <CardMedia
                component="img"
                height="140"
                image={course.image}
                alt={course.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {course.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {course.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>

      <h2 className="review-title">Avis des Étudiants</h2>
      <h4 className="review-count">
        +75 professeurs certifiés dans diverses matières et niveaux sont à votre
        aide.
      </h4>

      <div className="home-container">
        <Swiper
          spaceBetween={10}
          slidesPerView={2}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          loop={true}
          centeredSlides={false}
          modules={[Autoplay]}
        >
          {reviews.map((review) => (
            <SwiperSlide key={review.id}>
              <div className="review-box">
                <p className="review-text">"{review.review}"</p>
                <div className="review-author">
                  <h3>{review.name}</h3>
                  <span>{review.city}</span>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <br />
      <div className="home-b-advantages__image-container">
        <div className="home-b-advantages__image">
          <div className="home-video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/gJrzaUozDXo?si=NpOSMlQizMD_Q2um"
              title="Integration pour les élèves"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <p>Tutoriel Étudiant</p>
        </div>
        <div className="home-b-advantages__image">
          <div className="home-video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/2n0lW6SEmU0?si=ZiiISd_4s18vBhWM"
              title="Integration pour les professeurs"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <p>Tutoriel Professeur</p>
        </div>
      </div>
      <div className="home-container">
        <img src="/img/1st.png" alt="1St" className="home-image" />
        <img src="/img/certifie.png" alt="Certifie" className="home-image" />
        <img src="/img/secure.png" alt="Secure" className="home-image" />
      </div>
      <div className="home-banner-container">
        <img src="/img/Details2.jpg" alt="Banner" />
      </div>
      <div className="home-b-advantages__titleb">
        <h3>Accédez à vos cours rapidement et depuis n'importe où</h3>
        <div className="home-underlineb"></div>
        <p>
          Avec notre plateforme, accédez à vos cours de manière rapide et
          facile, où que vous soyez. Notre interface conviviale vous permet de
          naviguer facilement entre les leçons, de participer à des sessions en
          direct et d'interagir avec vos enseignants et vos camarades de classe.
          Profitez de la flexibilité et de la commodité pour apprendre à votre
          rythme et selon votre emploi du temps.
        </p>
      </div>
      <br />
      <br />
    </>
  );
};
