import React, { useState } from "react";
import { Typography, Paper, IconButton, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Policy = () => {
  const [openInscription, setOpenInscription] = useState(false);
  const [openDeroulement, setOpenDeroulement] = useState(false);
  const [openRemboursement, setOpenRemboursement] = useState(false);
  const [openSuppression, setOpenSuppression] = useState(false);

  const handleToggleInscription = () => {
    setOpenInscription(!openInscription);
  };

  const handleToggleDeroulement = () => {
    setOpenDeroulement(!openDeroulement);
  };

  const handleToggleRemboursement = () => {
    setOpenRemboursement(!openRemboursement);
  };

  const handleToggleSuppression = () => {
    setOpenSuppression(!openSuppression);
  };

  return (
    <>
      <div style={{ padding: "5%", fontSize: "16px" }}>
        <p>
          <strong className="policy-title">
            <u>REGLES ET POLITIQUE DE E-SWAYE</u>
          </strong>
        </p>
        <br />
        <p>
          Sur notre site web, la confidentialité de nos visiteurs est l'une de
          nos principales priorités. Ce document de politique de confidentialité
          contient les types d'informations collectées et enregistrées par notre
          site web et comment nous les utilisons.
        </p>
        <br />
        <p>
          Si vous avez des questions supplémentaires ou si vous avez besoin de
          plus d'informations sur notre politique de confidentialité, n'hésitez
          pas à nous contacter.
        </p>

        <br />
        <p>
          <strong
            style={{
              color: "green",
              fontFamily: "Georgia, serif",
              fontSize: "18px",
            }}
          >
            <u>Règles générales</u>
          </strong>
        </p>
        <br />
        <h5>Engagement envers la Protection des Données Personnelles</h5>
        <br />
        <p>
          Nous prenons la protection de vos données personnelles très au
          sérieux. Conformément aux lois et règlements en vigueur, notamment le
          Règlement Général sur la Protection des Données (RGPD), de l'Union
          Européenne, Commission Nationale de Contrôle de la Protection des
          Données (CNDP)... Nous nous engageons à garantir la sécurité et la
          confidentialité de vos informations personnelles. Nous collectons et
          traitons vos données uniquement dans le cadre des finalités légitimes
          et spécifiques pour lesquelles elles ont été fournies. En aucun cas,
          nous ne conservons de documents personnels dans nos bases de données.
        </p>
        <br />
        <br />
        <h5>Collecte et Utilisation des Données</h5>
        <br />
        <p>
          Toutes les données personnelles collectées sur notre site sont
          traitées conformément aux lois applicables. Nous veillons à ce que les
          informations soient recueillies de manière transparente et équitable,
          et qu'elles soient utilisées exclusivement aux fins pour lesquelles
          elles ont été fournies. Nous nous engageons à minimiser la collecte de
          données à ce qui est strictement nécessaire et à obtenir votre
          consentement explicite pour toute utilisation supplémentaire.
        </p>
        <br />
        <h5>Droits des Utilisateurs</h5>
        <br />
        <p>
          Vous avez le droit d'accéder, de rectifier, de supprimer ou de limiter
          le traitement de vos données personnelles. Vous pouvez également vous
          opposer au traitement de vos données ou demander leur portabilité.
          Pour exercer ces droits, vous pouvez nous contacter à l'adresse
          indiquée dans la section "Contact" de notre site. Nous nous engageons
          à répondre à vos demandes dans les meilleurs délais, conformément aux
          exigences légales.
        </p>
        <br />
        <h5>Sécurité des Données</h5>
        <br />
        <p>
          Nous mettons en œuvre des mesures techniques et organisationnelles
          appropriées pour protéger vos données personnelles contre tout accès
          non autorisé, divulgation, altération ou destruction. Nous utilisons
          des protocoles de sécurité robustes et surveillons régulièrement notre
          système pour détecter et prévenir toute vulnérabilité.
        </p>
        <br />
        <p>
          Tout document que vous choisissez de nous envoyer reste sécurisé. Pour
          les documents confidentiels nécessaires à des vérifications, une fois
          la vérification effectuée, ils sont immédiatement supprimés pour
          garantir une protection approfondie de vos données.Nous ne stockons
          jamais d'informations, de fichiers ou de documents à caractère
          personnel dans nos bases de données, quelles que soient les conditions
          ou les objectifs.
          <br />
          <strong>
            {" "}
            Il est strictement interdit de demander ou d'échanger des
            informations personnelles telles que des numéros de téléphone ou
            d'adresse e-mail pendant une séance. Tout manquement à cette règle
            est inacceptable et entraîne la suppression définitive de votre
            compte, y compris de tous vos cours et de votre solde, sans
            possibilité de remboursement ou de contestation. Toute communication
            en dehors du cadre du cours enseigné doit obligatoirement passer par
            l'administration.
          </strong>
        </p>
        <br />
        <h5>Mises à Jour de la Politique de Confidentialité</h5>
        <br />
        <p>
          Nous pouvons occasionnellement mettre à jour cette politique de
          confidentialité pour refléter les modifications de nos pratiques ou
          des lois applicables. Nous vous encourageons à consulter régulièrement
          cette page pour rester informé de nos pratiques de protection des
          données, nos services et nos prix. En cas de modifications
          significatives, nous vous en informerons par un avis bien visible sur
          notre site.
        </p>
        <br />
        <p>
          <strong
            style={{
              color: "green",
              fontFamily: "Georgia, serif",
              fontSize: "18px",
            }}
          >
            <u>Politique de Remboursement</u>
          </strong>
        </p>
        <br />
        <p>
          Pour la politique de remboursement, vous pouvez signaler si un élève
          ne procède pas au paiement après une séance, ou si un professeur exige
          un paiement anticipé sans dispenser de cours. Dans ces deux
          situations, l'utilisateur concerné sera automatiquement banni de la
          plateforme et ne pourra pas créer de nouveau compte.
        </p>
        {/*
        <p>
          Si un enseignant annule ou s'absente à plus de deux séances, vous avez
          le droit de demander un remboursement. Ce remboursement prendra la
          forme d'un solde ajouté à votre compte E-SWAYE. Toutefois, si vous
          n'avez pas encore payé et que vous avez seulement utilisé la séance
          gratuite, vous ne serez pas éligible à un remboursement.
        </p> 
        */}

        <p>
          <strong
            style={{
              color: "green",
              fontFamily: "Georgia, serif",
              fontSize: "18px",
            }}
          >
            <u>Politique de Paiement</u>
          </strong>
        </p>
        <br />
        <p>
          <strong>Pour professeur</strong>
        </p>
        <br />
        <p>
          Vous avez la liberté de choisir d'être payé soit avant chaque séance,
          soit après avoir dispensé votre cours. La liste des élèves ayant
          effectué le paiement pour le cours ou la séance est toujours
          accessible dans les détails du cours concerné. Veuillez la consulter
          pour n'accepter dans votre salle que les personnes ayant payé.
        </p>
        <br />
        <p>
          <strong>Pour élève</strong>
        </p>
        <br />
        <p>
          Avant d'effectuer le virement, veuillez vérifier attentivement le
          numéro de compte bancaire (RIB) envoyé ou indiqué dans le cours auquel
          vous souhaitez vous inscrire. Le cours sera accessible uniquement
          après la validation de votre virement par le professeur. Si vous
          effectuez le virement depuis une banque différente de celle indiquée,
          un délai de 48 heures peut être nécessaire pour la vérification. Il
          est conseillé d'utiliser la même banque afin d'éviter tout retard.
        </p>
        <p>
          <strong>Consentement</strong>
        </p>
        <p>
          En utilisant notre site web, vous consentez par la présente à notre
          politique de confidentialité et acceptez ses termes.
        </p>
      </div>
      <div style={{ paddingLeft: "40%", fontSize: "20px" }}>
        <p>
          <strong>
            <u>F.A.Q.</u>
          </strong>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10%",
        }}
      >
        <Paper
          elevation={3}
          style={{
            width: "85%",
            margin: "15px 0",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Comment se déroule l'inscription ?
          </Typography>
          <IconButton onClick={handleToggleInscription}>
            <ExpandMoreIcon />
          </IconButton>
          <Collapse in={openInscription}>
            &nbsp; Après avoir choisi un cours de la bibliothèque, vous avez la
            possibilité d'assister à une séance gratuite pour évaluer la qualité
            de l'enseignant et du cours. Une fois cette séance gratuite
            terminée, vous ne pouvez plus suivre le cours jusqu'au paiement.
            Sinon, vous pouvez choisir de payer dès le départ. Le paiement peut
            s'effectuer de deux manières :
            <ul style={{ marginLeft: "20px", textAlign: "left" }}>
              <li>Directement payer le cours choisi.</li>
              <li>Acheter un solde et payer le cours avec.</li>
            </ul>
          </Collapse>
        </Paper>

        <Paper
          elevation={3}
          style={{
            width: "85%",
            margin: "15px 0",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Comment se déroule la séance ?
          </Typography>
          <IconButton onClick={handleToggleDeroulement}>
            <ExpandMoreIcon />
          </IconButton>
          <Collapse in={openDeroulement}>
            &nbsp; En tant qu'élève, vous êtes tenu de respecter les règles de
            conduite dans chaque cours et de traiter les enseignants ainsi que
            les autres élèves avec respect. Après chaque séance, vous avez la
            possibilité de la noter.
          </Collapse>
        </Paper>

        <Paper
          elevation={3}
          style={{
            width: "85%",
            margin: "15px 0",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Quelle est la procédure de remboursement en cas d'annulation ?
          </Typography>
          <IconButton onClick={handleToggleRemboursement}>
            <ExpandMoreIcon />
          </IconButton>
          <Collapse in={openRemboursement}>
            &nbsp; Vous serez remboursé sous forme de crédit sur votre compte,
            vous permettant d'accéder à tout cours de votre choix, à condition
            que votre solde total soit suffisant pour couvrir le coût de ce
            cours.
          </Collapse>
        </Paper>

        <Paper
          elevation={3}
          style={{
            width: "85%",
            margin: "15px 0",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Comment supprimer mon compte ?
          </Typography>
          <IconButton onClick={handleToggleSuppression}>
            <ExpandMoreIcon />
          </IconButton>
          <Collapse in={openSuppression}>
            &nbsp; Pour éviter tout abus d'inscription et de désinscription, la
            suppression de votre compte est effectuée soit en soumettant une
            demande de suppression, soit en contactant un administrateur par
            e-mail de support ou par téléphone.
          </Collapse>
        </Paper>
      </div>
      <br />
    </>
  );
};

export default Policy;
