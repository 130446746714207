import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import DOMPurify from "dompurify";
import sha256 from "crypto-js/sha256";
import axiosInstance from "../../auth/axiosInstance";
import "../customCss/Register.css";

const Register = () => {
  let navigate = useNavigate();
  const maxCharacters = 30;

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
    const hideErrorMessage = () => {
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
    };
    if (errMsg) {
      hideErrorMessage();
    }
  }, [errMsg]);

  const sanitizeInputs = (data) => {
    return Object.keys(data).reduce((acc, key) => {
      acc[key] = DOMPurify.sanitize(data[key]);
      return acc;
    }, {});
  };

  const emptyForm = {
    prenom: "",
    nom: "",
    phone: "",
    email: "",
    password: "",
    role: "",
    niveau: "",
    ecole: "",
    ville: "",
  };

  const [open, setOpen] = useState(false);
  const [countdown, setCountdown] = useState(3);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    navigate("/");
  };

  useEffect(() => {
    let timer;
    if (open) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [open]);

  const [user, setUser] = useState(emptyForm);

  const { prenom, nom, phone, email, password, niveau, ecole, ville } = user;

  const [showEleve, setShowEleve] = useState(true);
  const [showProf, setShowProf] = useState(false);
  const [isEleveRequired, setIsEleveRequired] = useState(true);
  const [openResetDialog, setOpenResetDialog] = useState(false);

  const toggleFields = (option) => {
    setShowEleve(option === "eleve");
    setShowProf(option === "prof");
    setIsEleveRequired(option === "eleve");
  };

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const hashedPassword = sha256(password).toString();
      const dataToSend = {
        prenom,
        nom,
        phone,
        email,
        password: hashedPassword,
        role: showEleve ? "eleve" : "prof",
        niveau: showEleve ? niveau : null,
        ecole: showProf ? ecole : null,
        ville: showProf ? ville : null,
      };

      const sanitizedData = sanitizeInputs(dataToSend);

      const resp = await axiosInstance.post(
        "/auth/registration",
        sanitizedData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (resp.status === 200) {
        setOpen(true);
        setUser(emptyForm);
      }
    } catch (err) {
      if (!err?.response) {
        console.log("Erreur : ", err.message);
        setErrMsg("Serveur injoignable");
      } else if (err.response.status === 409) {
        setErrMsg("E-mail déjà utilisé");
      } else {
        setErrMsg("Veuillez réessayer plus tard");
      }
    }
  };

  const handleOpenResetDialog = () => {
    setOpenResetDialog(true);
  };

  const handleCloseResetDialog = () => {
    setOpenResetDialog(false);
  };

  return (
    <>
      <section
        className="vh-100 bg-image"
        style={{
          overflowY: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <style>
          {`
            form::-webkit-scrollbar {
              display: none;
            }
          `}
        </style>
        <form onSubmit={onSubmit}>
          <div id="wrap" className="input">
            <header className="input-header">
              <h1> Créer Un Compte</h1>
            </header>
            <section className="input-content">
              <div className="input-content-wrap">
                <dl className="inputbox">
                  <dt className="inputbox-title">Prénom</dt>
                  <dd className="inputbox-content">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        id="prenom"
                        className="form-control form-control-lg input-field"
                        name="prenom"
                        value={prenom}
                        onChange={onInputChange}
                        maxLength={maxCharacters}
                        required
                      />
                      <span className="character-count">
                        {prenom.length}/{maxCharacters}
                      </span>
                    </div>
                    <span className="underline"></span>
                  </dd>
                </dl>
                <dl className="inputbox">
                  <dt className="inputbox-title">Nom</dt>
                  <dd className="inputbox-content">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        id="nom"
                        className="form-control form-control-lg input-field"
                        name="nom"
                        value={nom}
                        onChange={onInputChange}
                        maxLength={maxCharacters}
                        required
                      />
                      <span className="character-count">
                        {nom.length}/{maxCharacters}
                      </span>
                    </div>
                    <span className="underline"></span>
                  </dd>
                </dl>
                <dl className="inputbox">
                  <dt className="inputbox-title">E-mail</dt>
                  <dd className="inputbox-content">
                    <input
                      type="email"
                      id="email"
                      className="form-control form-control-lg input-field"
                      name="email"
                      value={email}
                      onChange={onInputChange}
                      required
                    />
                    <span className="underline"></span>
                  </dd>
                </dl>
                <dl className="inputbox">
                  <dt className="inputbox-title">Téléphone</dt>
                  <dd className="inputbox-content">
                    <input
                      type="text"
                      id="phone"
                      className="form-control form-control-lg input-field"
                      name="phone"
                      value={phone}
                      onChange={onInputChange}
                      required
                    />
                    <span className="underline"></span>
                  </dd>
                </dl>
                <dl className="inputbox">
                  <dt className="inputbox-title">Mot de Passe</dt>
                  <dd className="inputbox-content">
                    <input
                      type="password"
                      id="password"
                      className="form-control form-control-lg input-field"
                      name="password"
                      value={password}
                      onChange={onInputChange}
                      required
                      minLength={8}
                    />
                    <span className="underline"></span>
                  </dd>
                </dl>
                <div className="checkbox-wrapper-1">
                  <input
                    id="eleve"
                    type="checkbox"
                    className="substituted"
                    aria-hidden="false"
                    onChange={() => toggleFields("eleve")}
                    checked={showEleve}
                    required={isEleveRequired}
                    aria-required={isEleveRequired}
                  />
                  <label htmlFor="eleve">Elève</label>

                  <input
                    id="prof"
                    type="checkbox"
                    className="substituted"
                    aria-hidden="true"
                    onChange={() => toggleFields("prof")}
                    checked={showProf}
                  />
                  <label htmlFor="prof">Professeur</label>
                </div>

                <div
                  style={{
                    display: showEleve ? "block" : "none",
                  }}
                >
                  <dl className="inputbox">
                    <dt className="inputbox-title">Niveau</dt>
                    <dd className="inputbox-content">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          id="niveau"
                          className="form-control form-control-lg input-field"
                          name="niveau"
                          value={niveau}
                          onChange={onInputChange}
                          maxLength={maxCharacters}
                        />
                        <span className="character-count">
                          {niveau.length}/{maxCharacters}
                        </span>
                      </div>
                      <span className="underline"></span>
                    </dd>
                  </dl>
                </div>

                <div
                  style={{
                    display: showProf ? "block" : "none",
                  }}
                >
                  <dl className="inputbox">
                    <dt className="inputbox-title">Ecole</dt>
                    <dd className="inputbox-content">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          id="ecole"
                          className="form-control form-control-lg input-field"
                          name="ecole"
                          value={ecole}
                          onChange={onInputChange}
                          maxLength={maxCharacters}
                        />
                        <span className="character-count">
                          {ecole.length}/{maxCharacters}
                        </span>
                      </div>
                      <span className="underline"></span>
                    </dd>
                  </dl>
                  <dl className="inputbox">
                    <dt className="inputbox-title">Ville</dt>
                    <dd className="inputbox-content">
                      <div className="input-wrapper">
                        <input
                          type="text"
                          id="ville"
                          className="form-control form-control-lg input-field"
                          name="ville"
                          value={ville}
                          onChange={onInputChange}
                          maxLength={maxCharacters}
                        />
                        <span className="character-count">
                          {ville.length}/{maxCharacters}
                        </span>
                      </div>
                      <span className="underline"></span>
                    </dd>
                  </dl>
                </div>
                <div className="form-check d-flex justify-content-center mb-5">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    defaultValue
                    id="checkbox"
                    required
                  />
                  <label
                    className="form-check-label"
                    style={{ cursor: "default" }}
                  >
                    J'accepte les conditions et politiques du site{" "}
                    <span
                      className="text-body"
                      onClick={handleOpenResetDialog}
                      style={{ cursor: "pointer" }}
                    >
                      <u>Conditions d'utilisation</u>
                    </span>
                  </label>
                </div>

                <Dialog open={openResetDialog} onClose={handleCloseResetDialog}>
                  <DialogContent>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "bold",
                        color: "#1a237e",
                        textAlign: "center",
                        textTransform: "uppercase",
                        marginBottom: "15px",
                      }}
                    >
                      Politique pour les Enseignants
                    </Typography>
                    <div style={{ marginLeft: "15px" }}>
                      <u>
                        <strong>Paiement</strong>
                      </u>
                      <br />
                      Chaque élève peut bénéficier d'une séance gratuite. Le
                      nombre et les noms des élèves inscrits à vos cours
                      s'afficheront dans les détails du cours concerné. Vous
                      avez la liberté de choisir d'être payé soit en début de
                      mois, soit en fin de mois, ou par séance. C'est vous qui
                      validez l'inscription de chaque élève à votre cours.
                      <br />
                      <br />
                    </div>
                    <div style={{ marginLeft: "15px" }}>
                      <u>
                        <strong>Déroulement</strong>
                      </u>
                      <br />
                      Vous devez accepter dans votre salle de cours
                      <strong> uniquement</strong> les élèves dont les noms sont
                      marqués comme ayant payé ou ceux bénéficiant d'une séance
                      gratuite. En tant qu'enseignant, vous êtes responsable de
                      maintenir un environnement d'apprentissage sûr et
                      respectueux pour tous les élèves. Vous devez vous engager
                      à respecter votre emploi du temps pour chaque cours que
                      vous enseignez. Toute annulation d'un cours doit être
                      mentionnée à l'administration. Une séance annulée doit
                      être signalée à l'administration et reprogrammée pour un
                      autre jour. Pour annuler un cours, vous devez l'enseigner
                      jusqu'au 27 du mois en cours. Vous pouvez signaler un
                      élève en mentionnant un motif à l'administration. Celui-ci
                      peut être exclu du cours si l'administration le juge
                      nécessaire. L'administration peut à n'importe quel moment
                      controler un cours, pour cela veuillez accepter un seul
                      administrateur qui a une adresse (@eswaye.com) à consulter
                      votre cours à tout moment de la séance.
                    </div>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "bold",
                        color: "#d32f2f",
                        marginTop: "20px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        marginBottom: "15px",
                      }}
                    >
                      Politique pour les Élèves
                    </Typography>
                    <div style={{ marginLeft: "15px" }}>
                      <u>
                        <strong>Inscription</strong>
                      </u>
                      <br />
                      Après avoir choisi un cours de la bibliothèque, vous avez
                      la possibilité d'assister à une séance gratuite pour
                      évaluer la qualité de l'enseignant et du cours. Une fois
                      cette séance gratuite terminée, vous ne pouvez plus suivre
                      le cours jusqu'au paiement. Sinon, vous pouvez choisir de
                      payer dès le départ. Le paiement peut s'effectuer de deux
                      manières :
                      <ul>
                        <li>
                          pour un cours en présentiel, le professeur peut
                          accepter le mode de paiement de son choix .
                        </li>
                        <li>
                          pour un cours en ligne, le montant doit être versé
                          directement au professeur.
                        </li>
                      </ul>
                    </div>
                    <div style={{ marginLeft: "15px" }}>
                      <u>
                        <strong>Déroulement</strong>
                      </u>
                      <br />
                      En tant qu'élève, vous êtes tenu de respecter les règles
                      de conduite dans chaque cours et de traiter les
                      enseignants ainsi que les autres élèves avec respect. Si
                      un enseignant estime nécessaire de demander votre
                      exclusion du cours, une enquête de l'administration sera
                      menée suite à cet événement. Si vous êtes exclu, aucun
                      remboursement ne sera accordé. Après chaque séance, vous
                      avez la possibilité de la noter. Votre évaluation est
                      importante pour la réputation du cours et de l'enseignant.
                      En cas d'erreur ou d'annulation de cours sans
                      notification, vous avez le droit de signaler le professeur.
                    </div>
                    <br />
                    <div style={{ marginLeft: "15px" }}>
                      <u>
                        <strong>Important</strong>
                      </u>
                      <br />
                      Pour les conditions, règles et politiques d'utilisation,
                      ainsi que la protection et le traitement des données au
                      sein du site, veuillez vous référer à la page suivante
                      <strong>
                        <Link to="/policy"> Politique </Link>
                      </strong>
                      . En acceptant ces règles, vous approuvez votre
                      consentement à nos politiques et règles.
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseResetDialog}>Fermer</Button>
                  </DialogActions>
                </Dialog>

                <div>
                  <p
                    ref={errRef}
                    className={errMsg ? "error" : "offscreen"}
                    style={{ textAlign: "center", fontSize: "14px" }}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    className="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                  >
                    <Snackbar
                      open={open}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      autoHideDuration={3000}
                      onClose={handleClose}
                      message={`Verifier votre email! Redirection à l'accueil dans ${countdown} s`}
                    />
                    Inscrivez-vous
                  </Button>
                </div>
                <p className="text-center text-muted mt-5 mb-0">
                  Avez-vous déjà un compte ?{" "}
                  <Link to={"/login"} className="fw-bold text-body">
                    <u>Se connecter</u>
                  </Link>
                </p>
              </div>
            </section>
          </div>
        </form>
      </section>
    </>
  );
};

export default Register;