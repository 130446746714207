import React, { createContext, useState, useEffect } from "react";
import getCountryFromIP from "./getCountry";

export const CountryContext = createContext();

export const CountryProvider = ({ children }) => {
  const [country, setCountry] = useState("MA");

  useEffect(() => {
    const fetchCountry = async () => {
      const detectedCountry = await getCountryFromIP();
      setCountry(detectedCountry);
    };
    fetchCountry();
  }, []);

  return (
    <CountryContext.Provider value={{ country, setCountry }}>
      {children}
    </CountryContext.Provider>
  );
};