import axios from "axios";

const getCountryFromIP = async () => {
  try {
    const response = await axios.get("https://ipinfo.io?token=6f82db96e29092");
    const countryCode = response.data.country;

    return countryCode || "MA";
  } catch (error) {
    return "MA";
  }
};

export default getCountryFromIP;