import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { AuthWrapper } from "./auth/AuthWrapper";
import ScrollToTop from "./components/structure/ScrollToTop";
import { CountryProvider } from "./components/structure/CountryContext";

function App() {
  return (
    <div className="App">
      <CountryProvider>
        <BrowserRouter>
          <ScrollToTop />
          <AuthWrapper />
        </BrowserRouter>
      </CountryProvider>
    </div>
  );
}

export default App;